import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {GALLERY_FILE_EXT, GALLERY_GRAPHICS_DESCRIPTION, GALLERY_GRAPHICS_TYPES, GALLERY_MIN_FILE_EXT, PUBLIC_GRAPHICS_IMAGES, TITLE_APPEND} from '@/Constants';
import {GalleryService} from '@galleries/services/gallery.service';
import {NguCarousel, NguCarouselConfig} from '@ngu/carousel'; // NguCarouselStore
import {UtilsService} from '@/services/utils.service';
import {GraphicsMap} from '@/models/GraphicsMap';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {UsersService} from '@users/services/users.service';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-gallery-graphics',
  templateUrl: './gallery-graphics.component.html',
  styleUrls: ['./_gallery-graphics.component.scss']
})
export class GalleryGraphicsComponent implements OnInit, OnDestroy {
  public galleryError: boolean;
  public GALLERY_MIN_FILE_EXT = GALLERY_MIN_FILE_EXT;
  // public GALLERY_FILE_EXT = GALLERY_FILE_EXT;
  public PUBLIC_GRAPHICS_IMAGES = PUBLIC_GRAPHICS_IMAGES;
  public GALLERY_GRAPHICS_TYPES_KEYS = Object.keys(GALLERY_GRAPHICS_TYPES);
  public GALLERY_GRAPHICS_TYPES = GALLERY_GRAPHICS_TYPES;
  public thumbs: GraphicsMap = new GraphicsMap([], [], []);
  private sub: Subscription;
  public carouselConfig: NguCarouselConfig = {
    grid: {xs: 2, sm: 4, md: 4, lg: 5, all: 0},
    slide: 1,
    speed: 400,
    // interval: {
    //   timing: 8000,
    //   initialDelay: 1000
    // },
    point: {
      visible: true
    },
    load: 2,
    touch: true,
    loop: true,
    custom: 'banner'
  };
  @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
  isLoadedCarousel: boolean;
  language = 'en';

  constructor(private _router: Router,
              private _utilsService: UtilsService,
              private _galleryService: GalleryService,
              private _title: Title,
              private _meta: Meta,
              private translate: TranslateService) {
    this.sub = this._galleryService.getGraphics().subscribe({
      next: res => {
        this.thumbs = res;
        setTimeout(() => { // To load after carousel points.
          this.isLoadedCarousel = true;
        });
      },
      error: err => {
        this.galleryError = UtilsService.handleGalleryError(err);
      }
    });
  }

  ngOnInit() {
    this._title.setTitle(`Graphics${TITLE_APPEND}`);
    this._meta.updateTag({name: 'description', content: GALLERY_GRAPHICS_DESCRIPTION});
    this._meta.updateTag({property: 'og:title', content: `Graphics${TITLE_APPEND}`});
    this._meta.updateTag({property: 'og:description', content: GALLERY_GRAPHICS_DESCRIPTION});
    this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
    this._meta.updateTag({property: 'og:url', content: 'https://www.sandbay.it/gallery-graphics/'});
    this._utilsService.createLinkForCanonicalURL('https://www.sandbay.it/gallery-graphics/');
    this.language = UsersService.getLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.browserLang = event.lang
      this.language = UsersService.getLang();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /**
   * Show single item when click on thumb.
   */
  showItem(name) {
    void this._router.navigate([{ outlets: { asset: ['graphic', name] } }]);
  }

  /**
   * Show item in new page when wheel click.
   */
  onWheelClick(e: MouseEvent, type: string, elem) {
    let target = '';
    e.preventDefault();
    if (e.button === 1) {
      if (type === 'videos') {
        target = 'https://www.youtube.com/watch?v=' + elem.thumbYoutube;
      } else {
        target = PUBLIC_GRAPHICS_IMAGES + elem.name + GALLERY_FILE_EXT;
      }
      if (typeof window !== 'undefined') {
        window.open(target);
      }
    }
  }
}
