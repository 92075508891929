import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, Params, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {combineLatest, Subscription} from 'rxjs';
import {
  FiltersConfig, GALLERY_FILE_EXT_NEW,
  GALLERY_ITEMSPERPAGE_DEFAULT,
  GALLERY_MODELS_DESCRIPTION,
  GALLERY_MODELS_TYPES,
  PUBLIC_MODELS_IMAGES_3D,
  TITLE_APPEND
} from '@/Constants';
import {Model} from '@/models/Model';
import {GalleryService} from '@galleries/services/gallery.service';
import {UtilsService} from '@/services/utils.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {UsersService} from '@users/services/users.service';

@Component({
  selector: 'app-gallery-models',
  templateUrl: './gallery-models.component.html',
  styleUrls: ['./_gallery-models.component.scss']
})
export class GalleryModelsComponent implements OnInit, OnDestroy {
  PUBLIC_MODELS_IMAGES_3D = PUBLIC_MODELS_IMAGES_3D;
  GALLERY_MODELS_TYPES = GALLERY_MODELS_TYPES;
  GALLERY_FILE_EXT_NEW = GALLERY_FILE_EXT_NEW;
  items: Model[];
  itemsCategory: string;
  itemsPages: number;
  private subs: Subscription[] = [];
  p: number;
  galleryError: boolean;
  count: number;
  loading: boolean;
  configs: FiltersConfig = {
    itemsPerPage: GALLERY_ITEMSPERPAGE_DEFAULT,
    collections: false,
    links: false,
    lods: false,
    isFiltered: false,
  };
  language = 'en';

  constructor(private _router: Router,
              private _title: Title,
              private _meta: Meta,
              private route: ActivatedRoute,
              private _utilsService: UtilsService,
              private _galleryService: GalleryService,
              private translate: TranslateService) {
    this.itemsCategory = '';
  }

  ngOnInit() {
    this._title.setTitle(`3D models${TITLE_APPEND}`);
    this._meta.updateTag({name: 'description', content: GALLERY_MODELS_DESCRIPTION});
    this._meta.updateTag({property: 'og:title', content: `3D models${TITLE_APPEND}`});
    this._meta.updateTag({property: 'og:description', content: GALLERY_MODELS_DESCRIPTION});
    this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
    this._meta.updateTag({property: 'og:url', content: `https://www.sandbay.it/gallery-models/`});
    this._utilsService.createLinkForCanonicalURL(`https://www.sandbay.it/gallery-models/`);
    this.getItems();
    this.language = UsersService.getLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.browserLang = event.lang
      this.language = UsersService.getLang();
    });
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  /**
   * Show single item when click on thumb.
   */
  showItem(name) {
    void this._router.navigate([`/gallery-models/asset-3d/${name}/.`]);
    // void this._router.navigate([{ outlets: { asset: ['3d', name, 1] } }]);
  }

  /**
   * Add page href to paginator.
   */
  goToPage() {
    let pageHref = `/gallery-models/.`;
    if (this.itemsCategory) {
      pageHref = `/gallery-models/${this.itemsCategory}/.`;
    }
    return pageHref;
  }

  /**
   * Get paginated items.
   */
  public getItems(page: number = 1,
                  configs: FiltersConfig = null) {
    this.configs = configs ? configs : this.configs;
    this.loading = true;
    if (configs) {
      void this._router.navigate([], { relativeTo: this.route, queryParams: {page: 1}, queryParamsHandling: 'merge' });
    }
    // this.paramsSub = this.route.data.subscribe((params: Data) => { // Data works only with data passed to the route.
    // this.subs.push(this.route.params.subscribe((params: Params) => {
    this.subs.push(combineLatest([this.route.params, this.route.queryParams],
      (params, queryParams) => ({ params, queryParams })).subscribe(result => {
      if (typeof result.params.category !== 'undefined') {
        if (Object.keys(this.GALLERY_MODELS_TYPES).indexOf(result.params.category) > -1) {
          this.itemsCategory = result.params.category;
          this._title.setTitle(`3D models - ${UtilsService.toTitleCase(this.itemsCategory)}${TITLE_APPEND}`);
          this._meta.updateTag({property: 'og:title', content: `3D models - ${UtilsService.toTitleCase(this.itemsCategory)}${TITLE_APPEND}`});
          this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
          this._meta.updateTag({property: 'og:url', content: `https://www.sandbay.it/gallery-models/${result.params.category}/`});
          this._utilsService.createLinkForCanonicalURL(`https://www.sandbay.it/gallery-models/${result.params.category}/`);
          this.translate.get(`menu.modelsDescription${UtilsService.toTitleCase(this.itemsCategory)}`)
              .subscribe( (description: string) => {
            const descriptionText = description.replace('<br/>', '');
            this._meta.updateTag({ name: 'description', content: descriptionText });
            this._meta.updateTag({property: 'og:description', content: descriptionText });
          });
        }
      }
      this.p = page;
      // if (configs) {
      //   this.p = 1; // On every configs change return on first page.
      if (result.queryParams.page && !isNaN(Number(result.queryParams.page))) {
        this.p = Number(result.queryParams.page);
      }
      this.subs.push(this._galleryService.getModels(
        this.itemsCategory,
        this.p,
        this.configs,
      ).subscribe({
        next: res => {
          this.items = res.items;
          this.itemsPages = res.pags;
          this.count = res.count;
          this.loading = false;
        },
        error: err => {
          this.galleryError = UtilsService.handleGalleryError(err);
          this.loading = false;
        }
      }));
    }));
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  /**
   * trackBy for performance.
   */
  public trackById(index, item) {
    return item.title;
  }

  /**
   * Show item in new page when wheel click.
   */
  onWheelClick(e: MouseEvent, name) {
    // e.preventDefault();
    // void this._router.navigate([{ outlets: { asset: ['3d', name, 1] } }]);
    // if (e.button === 1 && typeof window !== 'undefined') {
    //   window.open(target);
    // }
  }
}
