import {Component, Input, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ABOUT_DESCRIPTION, TITLE_APPEND} from '@/Constants';
import {UtilsService} from '@/services/utils.service';
import {UsersService} from '@users/services/users.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./_about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  @Input() public status: boolean;
  public logoRotate: string;
  public rotated1 = false;
  public rotated2 = false;
  public rotated3 = false;
  public rotated4 = false;
  public loadings = UtilsService.counter(4);
  language = 'en';

  constructor(private _utilsService: UtilsService,
              private _title: Title,
              private _meta: Meta,
              private translate: TranslateService) {
    this._title.setTitle(`About me${TITLE_APPEND}`);
    this._meta.updateTag({name: 'description', content: ABOUT_DESCRIPTION});
    this._meta.updateTag({property: 'og:title', content: `About me${TITLE_APPEND}`});
    this._meta.updateTag({property: 'og:description', content: ABOUT_DESCRIPTION});
    this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
    this._meta.updateTag({property: 'og:url', content: `https://www.sandbay.it/about/`});
    this._utilsService.createLinkForCanonicalURL(`https://www.sandbay.it/about/`);
    this.status = false;
    this.language = UsersService.getLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.browserLang = event.lang
      this.language = UsersService.getLang();
    });
  }
  /* TODO Google sky finto con un cursore "noi siamo qui" nello spazio
  <h2 class="titles">Noi siamo qui</h2>
  https://medium.com/@realTomaszKula/lazy-load-images-in-30-lines-of-code-3fe801223ffa
  https://www.npmjs.com/package/ng-lazyload-image
  */

  ngOnInit() {}
}
