
<div id="background">
  <!-- TODO https://blog.imagekit.io/how-to-deliver-responsive-images-to-browsers-across-multiple-devices-30cfae17390e -->
  <div *ngIf="theme === 'space'" id="space">
    <div id="black-background"></div>
    <div class="comets"></div>
    <span class="fadeIn" id="space-planet"></span>
    <span class="fadeIn" id="space-background"></span>
  </div>

  <div *ngIf="theme === 'snow'" id="snow">
    <div id="black-background"></div>
    <span class="fadeIn" id="snow-background"></span>
    <img draggable="false" class="fadeIn" alt="moon" id="moon" src="https://tools.obyte.it/assets/backs/moon.png" width="264px" height="250px" />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1536" preserveAspectRatio="xMidYMax slice">
      <defs>
        <filter id="blur">
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        </filter>
      </defs>
      <g fill="#FFF" fill-opacity=".3" transform="translate(55 42)">
        <g id="snow-bottom-layer">
          <ellipse class="snowflake" cx="6" cy="1009.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="138" cy="1110.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="398" cy="1055.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="719" cy="1284.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="760" cy="1155.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="635" cy="1459.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="478" cy="1335.5" rx="4" ry="4.2"></ellipse>
          <ellipse class="snowflake" cx="322" cy="1414.5" rx="4" ry="4.2"></ellipse>
          <ellipse class="snowflake" cx="247" cy="1234.5" rx="4" ry="4.2"></ellipse>
          <ellipse class="snowflake" cx="154" cy="1425.5" rx="4" ry="4.2"></ellipse>
          <ellipse class="snowflake" cx="731" cy="773.5" rx="4" ry="4.2"></ellipse>
          <ellipse class="snowflake" cx="599" cy="874.5" rx="4" ry="4.2"></ellipse>
          <ellipse class="snowflake" cx="339" cy="819.5" rx="3" ry="3.3"></ellipse>
          <ellipse class="snowflake" cx="239" cy="1004.5" rx="3" ry="3.3"></ellipse>
          <ellipse class="snowflake" cx="113" cy="863.5" rx="3" ry="3.3"></ellipse>
          <ellipse class="snowflake" cx="102" cy="1223.5" rx="3" ry="3.3"></ellipse>
          <ellipse class="snowflake" cx="395" cy="1155.5" rx="3" ry="3.3"></ellipse>
          <ellipse class="snowflake" cx="826" cy="943.5" rx="3" ry="3.3"></ellipse>
          <ellipse class="snowflake" cx="626" cy="1054.5" rx="3" ry="3.3"></ellipse>
          <ellipse class="snowflake" cx="887" cy="1366.5" rx="3" ry="3.3"></ellipse>
          <ellipse class="snowflake" cx="6" cy="241.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="138" cy="342.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="398" cy="287.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="719" cy="516.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="760" cy="387.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="635" cy="691.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="478" cy="567.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="322" cy="646.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="247" cy="466.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="154" cy="657.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="731" cy="5.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="599" cy="106.5" rx="4.5" ry="3.8"></ellipse>
          <ellipse class="snowflake" cx="339" cy="51.5" rx="4.5" ry="3.8"></ellipse>
          <circle class="snowflake" cx="239" cy="236.5" r="5"></circle>
          <circle class="snowflake" cx="113" cy="95.5" r="4"></circle>
          <circle class="snowflake" cx="102" cy="455.5" r="5"></circle>
          <circle class="snowflake" cx="395" cy="387.5" r="5"></circle>
          <circle class="snowflake" cx="826" cy="175.5" r="4"></circle>
          <circle class="snowflake" cx="626" cy="286.5" r="4"></circle>
          <circle class="snowflake" cx="887" cy="598.5" r="5"></circle>
        </g>
      </g>
      <g fill="#FFF" fill-opacity=".5" transform="translate(65 63)">
        <g id="snow-top-layer">
          <circle class="snowflake" cx="8" cy="776" r="5"></circle>
          <circle class="snowflake" cx="189" cy="925" r="7"></circle>
          <circle class="snowflake" cx="548" cy="844" r="6"></circle>
          <circle class="snowflake" cx="685" cy="1115" r="8"></circle>
          <circle class="snowflake" cx="858" cy="909" r="9"></circle>
          <circle class="snowflake" cx="874" cy="1438" r="5" transform="rotate(180 874 1438)"></circle>
          <circle class="snowflake" cx="657" cy="1256" r="5" transform="rotate(180 657 1256)"></circle>
          <circle class="snowflake" cx="443" cy="1372" r="6" transform="rotate(180 443 1372)"></circle>
          <circle class="snowflake" cx="339" cy="1107" r="6" transform="rotate(180 339 1107)"></circle>
          <circle class="snowflake" cx="24" cy="1305" r="5" transform="rotate(180 24 1305)"></circle>
          <circle class="snowflake" cx="8" cy="8" r="7"></circle>
          <circle class="snowflake" cx="189" cy="157" r="5"></circle>
          <circle class="snowflake" cx="548" cy="76" r="5"></circle>
          <circle class="snowflake" cx="685" cy="347" r="6"></circle>
          <circle class="snowflake" cx="858" cy="141" r="6"></circle>
          <circle class="snowflake" cx="874" cy="670" r="7" transform="rotate(180 874 670)"></circle>
          <circle class="snowflake" cx="657" cy="488" r="7" transform="rotate(180 657 488)"></circle>
          <circle class="snowflake" cx="443" cy="604" r="8" transform="rotate(180 443 604)"></circle>
          <circle class="snowflake" cx="339" cy="339" r="8" transform="rotate(180 339 339)"></circle>
          <circle class="snowflake" cx="24" cy="537" r="8" transform="rotate(180 24 537)"></circle>
        </g>
      </g>
    </svg>
  </div>

  <div *ngIf="theme === 'sea'" id="sea">
    <div class="black-background"></div>
    <div class="fadeIn" id="sea-background"></div>
    <div class="bubbles-container">
      <svg class="bubbles" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 701 1024" style="overflow: visible;">
        <g class="bubbles-large" stroke-width="7">
          <g><g transform="translate(10 940)">
            <circle cx="35" cy="35" r="35"></circle>
          </g>
          </g>
          <g><g transform="translate(373 940)">
            <circle cx="35" cy="35" r="35"></circle>
          </g>
          </g>
          <g><g transform="translate(408 940)">
            <circle cx="35" cy="35" r="35"></circle>
          </g>
          </g>
          <g><g transform="translate(621 940)">
            <circle cx="35" cy="35" r="35"></circle>
          </g>
          </g>
          <g><g transform="translate(179 940)">
            <circle cx="35" cy="35" r="35"></circle>
          </g>
          </g>
        </g>

        <g class="bubbles-small" stroke-width="4">
          <g><g transform="translate(147 984)">
            <circle cx="15" cy="15" r="15"></circle>
          </g>
          </g>
          <g><g transform="translate(255 984)">
            <circle cx="15" cy="15" r="15"></circle>
          </g>
          </g>
          <g><g transform="translate(573 984)">
            <circle cx="15" cy="15" r="15"></circle>
          </g>
          </g>
          <g><g transform="translate(429 984)">
            <circle cx="15" cy="15" r="15"></circle>
          </g>
          </g>
          <g><g transform="translate(91 984)">
            <circle cx="15" cy="15" r="15"></circle>
          </g>
          </g>
          <g><g transform="translate(640 984)">
            <circle cx="15" cy="15" r="15"></circle>
          </g>
          </g>
          <g><g transform="translate(321 984)">
            <circle cx="15" cy="15" r="15"></circle>
          </g>
          </g>
          <g><g transform="translate(376 984)">
            <circle cx="15" cy="15" r="15"></circle>
          </g>
          </g>
          <g><g transform="translate(376 984)">
            <circle cx="15" cy="15" r="15"></circle>
          </g>
          </g>
          <g><g transform="translate(497 984)">
            <circle cx="15" cy="15" r="15"></circle>
          </g>
          </g>
        </g>
      </svg>
    </div>
  </div>

  <div *ngIf="theme === 'nature'" id="nature">
    <div class="black-background"></div>
    <div class="fadeIn" id="nature-background"></div>
    <div class="bird-container bird-container--one"><div class="bird bird--one"></div></div>
    <div class="bird-container bird-container--two"><div class="bird bird--two"></div></div>
    <div class="bird-container bird-container--three"><div class="bird bird--three"></div></div>
    <div class="bird-container bird-container--four"><div class="bird bird--four"></div></div>
  </div>
</div>
