<div id="container" class="fadeIn def_back gallery-search container-md">
  <div id="container-border"></div>
  <div id="container-bottom"></div>
  <form id="gallery-search-form" [formGroup]="searchFormGroup" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill" class="col-12">
      <i class="input-icon fas fa-search fa-2x" matPrefix>&nbsp;</i>
      <label for="keywords" class="d-none">Searching keywords</label>
      <input matInput placeholder="Search..." name="keywords" formControlName="keywords"
              value="{{keywords}}" id="keywords" required autocomplete="off"> <!-- pattern="[a-zA-Z ]*" -->
      <button mat-button mat-icon-button matSuffix (click)="clearSearchField()" *ngIf="keywords"
              type="button" aria-label="Clear search">
        <i class="input-icon fas fa-15x fa-close"></i>
      </button>
      <!--<button mat-button mat-icon-button matSuffix *ngIf="keywords"
              type="submit" aria-label="Submit search">
        <i class="input-icon fas fa-2x fa-search"></i>
      </button>-->
    </mat-form-field>
    <!--<button type="submit" class="input-submit ms-2" [disabled]="!searchFormGroup.valid" [class.loading-input]="inputLoading">
      <i *ngIf="!inputLoading" class="input-icon fas fa-search"></i>
      <--<i *ngIf="inputLoading" class="input-icon fas fa-spinner fa-spin"></i>--
    </button>-->
  </form>

  <p class="gallery-empty" *ngIf="count !== 0">
    <span id="results" class="page-description"
          [innerHTML]="'search.result' | translate"></span>
    <span>&nbsp;{{keywordsResult.trim()}}.</span>
  </p>

  <p class="gallery-empty" *ngIf="count === 0">
    <i class="fas fa-sliders-h">&nbsp;</i>
    <span id="no-results" class="page-description"
          [innerHTML]="(keywords ? 'search.resultNo' : 'search.desc') | translate"></span>
  </p>

  <h2 *ngIf="count != 0" id="models-title" class="titles" [innerHTML]="'search.modelsTitle' | translate"></h2>
  <div *ngIf="!modelsItems && !galleryError && keywords" class="lds-css">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!modelsItems && galleryError" class="gallery-error">
    <i class="far fa-times-circle"></i>
    {{'errors.UNKNOWN' | translate}}
  </div>
  <ul class="thumbs" *ngIf="count != 0">
    <li *ngFor="let elem of modelsItems | paginate: { id: 'paginationM', itemsPerPage: pageSize, currentPage: pageM }" class="thumb">
      <a (click)="showModelItem(elem.name)" [routerLink]="'/gallery-models/asset-3d/' + elem.name + '/.'">
        <img draggable="false" [src]="PUBLIC_MODELS_IMAGES_3D + elem.name + '.min.jpg'"
             width="178px" height="178px" class="loading" />
        <span class="thumb_title" [innerHTML]="('general.lang' | translate) == 'it' ? elem.title : elem.title_en"></span>
      </a>
    </li>
  </ul>
  <div *ngIf="modelsItems?.length == 0 && graphicsItems?.length > 0">
    <p class="page-description" [innerHTML]="'search.resultNo' | translate"></p>
  </div>
  <div *ngIf="modelsItems" class="row">
    <div class="col-2"></div>
    <pagination-controls *ngIf="count !== 0" id="paginationM" class="pagination col-8" (pageChange)="pageM = $event"
                         maxSize="7" autoHide="true" previousLabel="" nextLabel="">
    </pagination-controls>
    <div class="col-2"></div>
  </div>

  <hr *ngIf="count !== 0" />

  <h2 *ngIf="count != 0" id="graphics-title" class="titles" [innerHTML]="'search.graphicsTitle' | translate"></h2>
  <div *ngIf="!graphicsItems && !galleryError && keywords" class="lds-css">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!graphicsItems && galleryError" class="gallery-error">
    <i class="far fa-times-circle"></i>
    {{'errors.UNKNOWN' | translate}}
  </div>
  <ul class="thumbs" *ngIf="count != 0">
    <li *ngFor="let elem of graphicsItems | paginate: { id: 'paginationG', itemsPerPage: pageSize, currentPage: pageG }" class="thumb">
      <a (click)="showGraphicItem(elem.name)" [routerLink]="'/gallery-graphics/asset-graphic/' + elem.name + '/.'">
<!--        <img draggable="false" [src]="PUBLIC_GRAPHICS_IMAGES + elem.name + '.min.jpg'"-->
<!--             width="178px" height="178px" class="loading" />-->
        <picture *ngIf="elem.type !== 'video'">
          <source [srcset]="PUBLIC_GRAPHICS_IMAGES + 'webp/' + elem.name + '.min.webp'"  type="image/webp">
          <img draggable="false" [src]="PUBLIC_GRAPHICS_IMAGES + elem.name + GALLERY_MIN_FILE_EXT"
               [alt]="elem.title" width="180px" height="180px">
        </picture>
        <picture *ngIf="elem.type === 'video'">
          <source [srcset]="'https://img.youtube.com/vi_webp/' + elem.thumb_youtube + '/mqdefault.webp'"  type="image/webp">
          <img draggable="false" [src]="'https://img.youtube.com/vi/' + elem.thumb_youtube + '/mqdefault.jpg'"
               [alt]="elem.title" width="180px" height="101px">
        </picture>
        <span class="thumb_title" [innerHTML]="('general.lang' | translate) == 'it' ? elem.title : elem.title_en"></span>
      </a>

    </li>
  </ul>
  <div *ngIf="graphicsItems?.length == 0 && modelsItems?.length > 0">
    <p class="page-description" [innerHTML]="'search.resultNo' | translate"></p>
  </div>
  <div *ngIf="graphicsItems" class="row">
    <div class="col-2"></div>
    <pagination-controls *ngIf="count !== 0" id="paginationG" class="pagination col-8" (pageChange)="pageG = $event"
                         maxSize="100" autoHide="true" previousLabel="" nextLabel="">
    </pagination-controls>
    <div class="col-2"></div>
  </div>
</div>
