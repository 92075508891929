<div id="container" class="fadeIn def_back about-us">
  <div id="container-border"></div>
  <div id="container-bottom"></div>
  <h1 class="titles">
    <span class="title-icon">
      <i class="fa-users fas"></i>
    </span>
    {{'menu.sezChiSiamo' | translate }}
  </h1>

  <div class="row">
    <div id="page-description" class="col-12">
      <p [innerHTML]="(language === 'pirate' ? 'Yarr! ' : '') + ('menu.aboutDescription' | translate)" class="text-center mb-4"></p>
    </div>
  </div>

  <div class="whoare-container">
    <!--<ul id="loadings">
      <li class="l-container" *ngFor="let item of loadings">
        <app-loading></app-loading>
      </li>
    </ul>-->
    <ul id="whoare-list">
      <!-- 3D Models -->
      <li id="models3d" (mouseover)="logoRotate='models'" (mouseout)="logoRotate=''"
          (click)="rotated1 = !rotated1" [class.rotated]="rotated1">
        <div class="front">
          <!-- <span class="back-title"></span> -->
          <h2>{{'contacts.whoModelsTitle' | translate }}</h2>
          <span class="whoare-img"></span>
        </div>
        <div class="back">
          <h3>{{'contacts.whoModelsTitle' | translate }}</h3>
          <span [innerHTML]="'contacts.whoModelsDesc' | translate"></span>
        </div>
      </li>
      <!-- Web Sites -->
      <li id="web" (mouseover)="logoRotate='sites'" (mouseout)="logoRotate=''"
          (click)="rotated2 = !rotated2" [class.rotated]="rotated2">
        <div class="front">
          <!-- <span class="back-title"></span> -->
          <h2>{{'menu.sezWebsites' | translate }}</h2>
          <span class="whoare-img"></span>
        </div>
        <div class="back">
          <h3>{{'menu.sezWebsites' | translate }}</h3>
          <span [innerHTML]="'contacts.whoWebsitesDesc' | translate"></span>
        </div>
      </li>
      <!-- Graphics -->
      <li id="graphics" (mouseover)="logoRotate='graphics'" (mouseout)="logoRotate=''"
          (click)="rotated3 = !rotated3" [class.rotated]="rotated3">
        <div class="front">
          <!-- <span class="back-title"></span> -->
          <h2 [innerHTML]="'menu.sezGraphics' | translate"></h2>
          <span class="whoare-img"></span>
        </div>
        <div class="back">
          <h3 [innerHTML]="'menu.sezGraphics' | translate"></h3>
          <span [innerHTML]="'contacts.whoGraphicsDesc' | translate"></span>
        </div>
      </li>
      <!-- Tools -->
      <li id="tools" (mouseover)="logoRotate='tools'" (mouseout)="logoRotate=''"
          (click)="rotated4 = !rotated4" [class.rotated]="rotated4">
        <div class="front">
          <!-- <span class="back-title"></span> -->
          <h2>{{'menu.sezTools' | translate }}</h2>
          <span class="whoare-img"></span>
        </div>
        <div class="back">
          <h3>{{'menu.sezTools' | translate }}</h3>
          <span [innerHTML]="'contacts.whoToolsDesc' | translate"></span>
        </div>
      </li>
    </ul>
    <span class="whoare-logo"> <!-- routerLink="/about-me"  -->
      <span [ngClass]="logoRotate"></span>
        <!--<span>About Me</span>-->
    </span>
  </div>

  <h2 class="titles">{{'contacts.contactsTitle' | translate }} <i class="fas fa-envelope"></i></h2>
  <p class="page-description" [innerHTML]="'contacts.contactsDesc' | translate"></p>

  <div id="contacts-list">

    <a id="contacts-youtube-button" class="fab fa-4x fa-youtube-square"
       href="https://www.youtube.com/channel/UCc9HSLlz1vJ5-nyHPVo1_3g" aria-label="YouTube channel"
       target="_blank" rel="noopener noreferrer"></a>
    <a id="contacts-facebook-button" class="fab fa-4x fa-facebook-square"
       href="https://www.facebook.com/Idrobyteit-206158339482848/" aria-label="Facebook profile"
       target="_blank" rel="noopener noreferrer"></a>
    <a id="contacts-pinterest-button" class="fab fa-4x fa-pinterest-square"
       href="https://www.pinterest.it/sandbayit/" aria-label="Pinterest profile"
       target="_blank" rel="noopener noreferrer"></a>
    <!--<a id="contacts-linkedin-button" class="fab fa-4x fa-linkedin"
       href="https://www.linkedin.com/in/andrea-epifano-90213888/" aria-label="LinkedIn profile"
       target="_blank" rel="noopener noreferrer"></a>-->
    <a id="contacts-github-button" class="fab fa-4x fa-github-square"
       href="https://github.com/Sandman90" aria-label="GitHub profile"
       target="_blank" rel="noopener noreferrer"></a>
    <a id="contacts-stackoverflow-button" class="fa-stack-overflow-square"
       href="https://stackoverflow.com/users/13201349/sandman" aria-label="Stackoverflow profile"
       target="_blank" rel="noopener noreferrer"><i class="fab fa-3x fa-stack-overflow"></i></a>

  </div>
  <p *ngIf="status" id="message-sent">{{'comments.sent' | translate }}</p>

  <div class="row">
    <app-contacts [labels]="('contactMe' | translate)" class="col-md-12 col-12"></app-contacts>
  </div>
</div>
