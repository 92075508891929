import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {PUBLIC_GRAPHICS_IMAGES, PUBLIC_MODELS_IMAGES_3D, TITLE_APPEND, GALLERY_MIN_FILE_EXT} from '@/Constants';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {GalleryService} from '@galleries/services/gallery.service';
import {UtilsService} from '@/services/utils.service';

@Component({
  selector: 'app-gallery-search',
  templateUrl: './gallery-search.component.html',
  styleUrls: ['./_gallery-search.component.scss']
})
export class GallerySearchComponent implements OnInit, OnDestroy {
  PUBLIC_MODELS_IMAGES_3D = PUBLIC_MODELS_IMAGES_3D;
  PUBLIC_GRAPHICS_IMAGES = PUBLIC_GRAPHICS_IMAGES;
  GALLERY_MIN_FILE_EXT = GALLERY_MIN_FILE_EXT;
  modelsItems: {name, title, titleEn}[];
  graphicsItems: {name, title, titleEn, type, thumb_youtube}[];
  count = 0;
  keywords: string;
  keywordsResult: string;
  private sub: Subscription;
  private _paramsSubscription: Subscription;
  inputLoading = false;
  galleryError: boolean;
  searchFormGroup: UntypedFormGroup;
  pageSize = 10;
  pageM = 1;
  pageG = 1;
  private qRegex = /[^-\w\s]+/g;

  constructor(private _router: Router,
              private _title: Title,
              private _meta: Meta,
              private _activatedRoute: ActivatedRoute,
              private _galleryService: GalleryService,
              private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this._title.setTitle(`Search${TITLE_APPEND}`);
    this._meta.updateTag({ name: 'robots', content: 'nofollow,noindex' });
    this._meta.updateTag({ name: 'description', content: `Search in gallery.` });
    this._meta.updateTag({property: 'og:title', content: `Search${TITLE_APPEND}`});
    this._meta.updateTag({property: 'og:description', content: `Search in gallery.`});
    this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
    // this._meta.updateTag({property: 'og:url', content: `https://www.sandbay.it/search/`});
    // this._galleryService.createLinkForCanonicalURL(`https://www.sandbay.it/search/`);
    this._paramsSubscription = this._activatedRoute.queryParams.subscribe((params: Params) => {
      this.keywords = typeof params.q !== 'undefined' && params.q ? params.q.replace(this.qRegex, '') : '';
      if (this.keywords) {
        this.getItems(this.keywords);
      }
    });
    this.searchFormGroup = this.fb.group({
      keywords: [this.keywords, [ Validators.required ]],
    });
  }

  ngOnDestroy() {
    this._paramsSubscription?.unsubscribe();
    this.sub?.unsubscribe();
  }

  onSubmit() {
    // this.getItems(this.searchFormGroup.value.keywords);

    void this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: { q: this.searchFormGroup.value.keywords.replace(this.qRegex, '') },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  public getItems(keywords: string = '') {
    this.sub = this._galleryService.search(
      keywords,
    ).subscribe({
      next: res => {
        if (res.result !== null && res.code === 0) {
          this.inputLoading = false;
          this.modelsItems = res.result.items.gallery_models; // this.modelsItems = res.result.models;
          this.graphicsItems = res.result.items.gallery_graphics; // this.graphicsItems = res.result.graphics;
          this.count = res.result.count;
          this.keywordsResult = res.result.keywords;
        } else {
          console.warn('Search failed:', res.code);
        }
      },
      error: err => {
        this.galleryError = UtilsService.handleGalleryError(err);
      }
    });
  }

  showModelItem(name) {
    void this._router.navigate([`/gallery-models/asset-3d/${name}/.`]);
    // void this._router.navigate([{ outlets: { asset: ['3d', name, 1] } }]);
  }

  showGraphicItem(name) {
    void this._router.navigate([{ outlets: { asset: ['graphic', name] } }]);
  }

  clearSearchField() {
    this.keywords = '';
  }
}
