import {Component, OnDestroy, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {GALLERY_WEBSITES_DESCRIPTION, OBYTE_HOST_UTIL, TITLE_APPEND} from '@/Constants';
import {Website} from '@/models/Website';
import {GalleryService} from '@galleries/services/gallery.service';
import {UtilsService} from '@/services/utils.service';
import {UsersService} from '@users/services/users.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-gallery-websites',
  templateUrl: './gallery-websites.component.html',
  styleUrls: ['./_gallery-websites.component.scss']
})
export class GalleryWebsitesComponent implements OnInit, OnDestroy {
  public galleryError: boolean;
  public OBYTE_HOST_UTIL = OBYTE_HOST_UTIL;
  public items: Website[];
  private sub: Subscription;
  more: boolean;
  language = 'en';

  constructor(private _utilsService: UtilsService,
              private _galleryService: GalleryService,
              private _title: Title,
              private _meta: Meta,
              private translate: TranslateService) {}

  ngOnInit() {
    this._title.setTitle(`Websites${TITLE_APPEND}`);
    this._meta.updateTag({name: 'description', content: GALLERY_WEBSITES_DESCRIPTION});
    this._meta.updateTag({property: 'og:title', content: `Websites${TITLE_APPEND}`});
    this._meta.updateTag({property: 'og:description', content: GALLERY_WEBSITES_DESCRIPTION});
    this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
    this._meta.updateTag({property: 'og:url', content: 'https://www.sandbay.it/gallery-websites/'});
    this._utilsService.createLinkForCanonicalURL('https://www.sandbay.it/gallery-websites/');
    // this._meta.updateTag({ name: 'robots', content: `index, nofollow` });
    // TODO async pipe handle errors https://codinglatte.com/posts/angular/angular-async-pipe-handle-errors/
    this.sub = this._galleryService.getWebsites().subscribe({
      next: res => {
        this.items = res.filter(i => !i.draft);
      },
      error: err => {
        this.galleryError = UtilsService.handleGalleryError(err);
      }
    });
    this.language = UsersService.getLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.browserLang = event.lang
      this.language = UsersService.getLang();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * trackBy for performance.
   */
  public trackById(index, item) {
    return item.title;
  }

  /**
   *
   */
  public showOlder() {
    this.more = true;

  }
}
